// @flow

import React from "react";
import { Typography, Divider } from "@material-ui/core";

import { removeMD5Prefix } from "../../utils/attachmentHelper";

import style from "./style.module.scss";

type Props = {
    value: string[],
};

const Attachments = ({ value }: Props) => (
    <React.Fragment>
        <div className={style.sectiontitle}>
            <Typography variant="h5">Bijlagen</Typography>
            <Divider />
        </div>
        {value.map((attachment, index) => (
            <Typography key={index} variant="body1">
                {removeMD5Prefix(attachment)}
            </Typography>
        ))}
    </React.Fragment>
);

export default Attachments;
