// @flow

import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";

import style from "./style.module.scss";

export type Value = {
    center: string,
};

type Props = {
    value: Value,
};

const LABEL_VARIANT = "body2";
const VALUE_VARIANT = "body1";

const General = ({ value }: Props) => {
    return (
        <React.Fragment>
            <div className={style.sectiontitle}>
                <Typography variant="h5">Competence Center</Typography>
                <Divider />
            </div>
            <Grid container>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        Competence center
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.center}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default General;
