// @flow

import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import moment from "moment";

import style from "./style.module.scss";

export type Value = {
    function: string,
    eind: number,
    begin: number,
    dailyRate: string,
    clientLocation: string,
    noticePeriod: string,
    trailPeriod: string,
    termOfPayment: string,
};

type Props = {
    value: Value,
};

const LABEL_VARIANT = "body2";
const VALUE_VARIANT = "body1";

const General = ({ value }: Props) => {
    return (
        <React.Fragment>
            <div className={style.sectiontitle}>
                <Typography variant="h5">Informatie</Typography>
                <Divider />
            </div>
            <Grid container>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        Functie / Profiel
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.function}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>Dagtarief</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {`€ ${value.dailyRate}`}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>Begindatum</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {moment.unix(value.begin).format("DD/MM/YYYY")}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>Einddatum</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.eind > 0
                            ? moment.unix(value.eind).format("DD/MM/YYYY")
                            : null}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        Klant/locatie
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.clientLocation}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        Opzegtermijn
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.noticePeriod}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        Proefperiode
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.trailPeriod}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        Betalingstermijn
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.termOfPayment}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default General;
