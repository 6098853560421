// @flow

import React, { Fragment } from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import moment from "moment";

import style from "./style.module.scss";

export type Value = {
    eindKlant: string,
    facturatiemail: string,
    facturatie: string,
    projectnaam: string,
    refr: string,
    eind: number,
    begin: number,
    sales: string,
    order: string,
    orderNummer: string,
};

type Props = {
    value: Value,
    freelance?: boolean,
};

const LABEL_VARIANT = "body2";
const VALUE_VARIANT = "body1";

const General = ({ value, freelance }: Props) => {
    return (
        <Fragment>
            <div className={style.sectiontitle}>
                <Typography variant="h5">Algemeen</Typography>
                <Divider />
            </div>
            <Grid container>
                {!freelance && (
                    <Fragment>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={LABEL_VARIANT}>
                                Eindklant
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={VALUE_VARIANT}>
                                {value.eindKlant}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={LABEL_VARIANT}>
                                Email facturatiedienst klant
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={VALUE_VARIANT}>
                                {value.facturatiemail}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={LABEL_VARIANT}>
                                facturatie
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={VALUE_VARIANT}>
                                {value.facturatie}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={LABEL_VARIANT}>
                                Sales / accountmanager
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={VALUE_VARIANT}>
                                {value.sales || "-"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={LABEL_VARIANT}>
                                Projectnaam
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={VALUE_VARIANT}>
                                {value.projectnaam || "-"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={LABEL_VARIANT}>
                                Ref /PO nr klant
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={VALUE_VARIANT}>
                                {value.refr || "-"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={LABEL_VARIANT}>
                                Order
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography variant={VALUE_VARIANT}>
                                {value.order}
                            </Typography>
                        </Grid>
                        {value.order === "bestaande order" ? (
                            <>
                                <Grid item xs={6} sm={3}>
                                    <Typography variant={LABEL_VARIANT}>
                                        Ordernummer
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <Typography variant={VALUE_VARIANT}>
                                        {value.orderNummer}
                                    </Typography>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={6} sm={3}></Grid>
                                <Grid item xs={6} sm={3}></Grid>
                            </>
                        )}
                    </Fragment>
                )}
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>Begindatum</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {moment.unix(value.begin).format("DD/MM/YYYY")}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>Einddatum</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.eind > 0
                            ? moment.unix(value.eind).format("DD/MM/YYYY")
                            : "n.v.t"}
                    </Typography>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default General;
