// @flow

import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import moment from "moment";

import { type Value as ScheduledValue } from "../Scheduled";
import { type Value as MilestoneValue } from "../Milestone";
import { type Value as TimeAndMaterialValue } from "../TimeAndMaterial";
import { type Value as FixedPriceValue } from "../FixedPrice";

import style from "./style.module.scss";

export type Value = {
    type: 1 | 2 | 3 | 4,
    fixedPrice: FixedPriceValue,
    milestones: MilestoneValue[],
    timeAndMaterial: TimeAndMaterialValue,
    sheduled: ScheduledValue,
};

type Props = {
    value: Value,
};

const LABEL_VARIANT = "body2";
const VALUE_VARIANT = "body1";

const getInvoicingTypeDescription = (type: number) => {
    switch (type) {
        case 1:
            return "Fixed Price";

        case 2:
            return "Fixed price met milestones";

        case 3:
            return "Time & Material";

        case 4:
            return "Scheduled invoicing";

        default:
            return "";
    }
};

const getIntervalDescription = (type: number) => {
    switch (type) {
        case 1:
            return "Maandelijks";

        case 2:
            return "Per kwartaal";

        case 3:
            return "Half jaarlijks";

        case 4:
            return "Jaarlijks";

        default:
            return "";
    }
};

const Invoicing = ({ value }: Props) => {
    return (
        <React.Fragment>
            <div className={style.sectiontitle}>
                <Typography variant="h5">Facturatie</Typography>
                <Divider />
            </div>
            <Grid container>
                <Grid item xs={3}>
                    <Typography variant={LABEL_VARIANT}>Type</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant={VALUE_VARIANT}>
                        {getInvoicingTypeDescription(value.type)}
                    </Typography>
                </Grid>
                {value.type === 1 && (
                    <React.Fragment>
                        <Grid item xs={3}>
                            <Typography variant={LABEL_VARIANT}>
                                Uren per week
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant={VALUE_VARIANT}>
                                {value.fixedPrice.hours}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant={LABEL_VARIANT}>
                                Prijs
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant={VALUE_VARIANT}>
                                {`€ ${value.fixedPrice.price}`}
                            </Typography>
                        </Grid>
                    </React.Fragment>
                )}
                {value.type === 2 && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant={LABEL_VARIANT}>
                                MileStones
                            </Typography>
                        </Grid>
                        {value.milestones.map(mileStone => (
                            <React.Fragment key="mileStone.price">
                                <Grid item xs={4}>
                                    <Typography variant={VALUE_VARIANT}>
                                        € {mileStone.price}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant={VALUE_VARIANT}>
                                        {moment
                                            .unix(mileStone.date)
                                            .format("DD/MM/YYYY")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant={VALUE_VARIANT}>
                                        {mileStone.description}
                                    </Typography>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                )}
                {value.type === 3 && (
                    <React.Fragment>
                        <Grid item xs={3}>
                            <Typography variant={LABEL_VARIANT}>
                                Aantal uren per week
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant={VALUE_VARIANT}>
                                {value.timeAndMaterial.hours}
                            </Typography>
                        </Grid>
                    </React.Fragment>
                )}
                {value.type === 4 && (
                    <React.Fragment>
                        <Grid item xs={3}>
                            <Typography variant={LABEL_VARIANT}>
                                Facturatie interval
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant={VALUE_VARIANT}>
                                {getIntervalDescription(
                                    value.sheduled.interval,
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant={LABEL_VARIANT}>
                                Prijs
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant={VALUE_VARIANT}>
                                {value.sheduled.variable
                                    ? "Variabel"
                                    : `${value.sheduled.price}`}
                            </Typography>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </React.Fragment>
    );
};

export default Invoicing;
