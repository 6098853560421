// @flow

import React from "react";
import { Divider, Typography } from "@material-ui/core";

// import Facturatie from "../Consultants/Facturatie";
import { type InvoiceStep } from "../Consultants/Stepper/Steps/Invoicing";
import ConsultantList from "../Consultants/List/ConsultantList";
import style from "./style.module.scss";

export type ConsultantType = {
    consultant: string,
    aantalDagen: string,
    getekend: boolean,
    salesRate: string,
    competenceCenter: string,
    facturatie: InvoiceStep[],
};

// Define props
type Props = {
    value: ConsultantType[],
    endCustomer: string,
    invoiceBy: string,
};

const Consultants = ({ value, endCustomer, invoiceBy }: Props) => (
    <React.Fragment>
        <div className={style.sectiontitle}>
            <Typography variant="h5">Consultants</Typography>
            <Divider />
            <ConsultantList
                value={value}
                endCustomer={endCustomer}
                invoiceBy={invoiceBy}
                editable={false}
            />
        </div>
    </React.Fragment>
);

export default Consultants;
