// @flow

import React from "react";

import { Typography, Divider } from "@material-ui/core";

import style from "./style.module.scss";

type Props = {
    value: string,
};

const Remarks = ({ value }: Props) => (
    <React.Fragment>
        <div className={style.sectiontitle}>
            <Typography variant="h5">Opmerkingen</Typography>
            <Divider />
        </div>
        <Typography variant="body1">{value}</Typography>
    </React.Fragment>
);

export default Remarks;
