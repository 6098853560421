// @flow

import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";

import style from "./style.module.scss";

export type Value = {
    company: string,
    legalForm: string,
    address: string,
    btwNumber: string,
    manager: string,
};

type Props = {
    value: Value,
};

const LABEL_VARIANT = "body2";
const VALUE_VARIANT = "body1";

const General = ({ value }: Props) => {
    return (
        <React.Fragment>
            <div className={style.sectiontitle}>
                <Typography variant="h5">Dienstverlener</Typography>
                <Divider />
            </div>
            <Grid container>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>Bedrijf</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.company}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        Juridische vorm
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.legalForm}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>Adres</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.address}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>BTW-nummer</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.btwNumber}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>Manager</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.manager}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default General;
