// @flow

import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";

import style from "./style.module.scss";

export type Value = {
    name: string,
    firstName: string,
    phoneNumber: string,
    email: string,
    nationality: string,
    freelanceType: string,
};

type Props = {
    value: Value,
};

const LABEL_VARIANT = "body2";
const VALUE_VARIANT = "body1";

const General = ({ value }: Props) => {
    return (
        <React.Fragment>
            <div className={style.sectiontitle}>
                <Typography variant="h5">Freelancer</Typography>
                <Divider />
            </div>
            <Grid container>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>Naam</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.name}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>Voornaam</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.firstName}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        Type Freelance
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.freelanceType}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        privé GSM nummer
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.phoneNumber}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        Privé email adres
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.email}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        Nationaliteit
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.nationality}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default General;
