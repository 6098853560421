// @flow

import React from "react";
import { navigate } from "gatsby";
import { Paper } from "@material-ui/core";

import Summary from "../components/Summary";

import style from "./style.module.scss";

/**
 * Homepage
 */
const ConfirmationPage = (props: *) => {
    return (props?.location?.state?.fields &&
        props?.location?.state?.profile) ||
        props?.location?.state?.freelance ? (
        <Paper className={style.wrapper}>
            <Summary
                profile={props.location.state.profile}
                fields={props.location.state.fields}
                onBack={() => navigate("/")}
                freelance={props.location.state.freelance}
            />
        </Paper>
    ) : null;
};

export default ConfirmationPage;
