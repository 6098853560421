// @flow

import React, { Component } from "react";

import { type Fields } from "../Form/index";
import General from "./General";
import Invoicing from "./Invoicing";
import Consultants from "./Consultants";
import FreelanceGeneral from "./FreelanceGeneral";
import FreelanceInfo from "./FreelanceInfo";
import CompetenceCenter from "./CompetenceCenter";
import ServiceProvider from "./ServiceProvider";
import Remarks from "./Remarks";
import Attachments from "./Attachments";
import Tax from "./Tax";
import { Button, Typography } from "@material-ui/core";

type Props = {
    profile: *,
    fields: Fields,
    onBack: () => void,
    freelance?: boolean,
};

class Summary extends Component<Props> {
    render() {
        const { fields, profile, freelance } = this.props;
        return (
            <div>
                <Typography color="primary" variant="h5">
                    Bedankt!
                </Typography>
                <Typography color="primary" variant="h6">
                    We hebben je verzoek goed ontvangen. Hieronder vind je een
                    overzicht van de aanvraag.
                </Typography>
                {fields.general.ccMailadres ? (
                    <Typography>
                        Een kopie is verstuurd naar {profile.email} en{" "}
                        {fields.general.ccMailadres}.
                    </Typography>
                ) : (
                    <Typography>
                        Een kopie is verstuurd naar {profile.email}.
                    </Typography>
                )}

                {!freelance && <General value={fields.general} />}
                {!freelance && fields.tax.bedrijfsvoorheffing == "ja" && (
                    <Tax value={fields.tax} />
                )}
                {!freelance && <Invoicing value={fields.invoicing} />}
                {!freelance && fields.consultants.length > 0 && (
                    <Consultants
                        value={fields.consultants}
                        endCustomer={fields.general.eindKlant}
                        invoiceBy={fields.general.facturatie}
                        editable={false}
                    />
                )}
                {freelance && (
                    <CompetenceCenter value={fields.competenceCenter} />
                )}
                {freelance && (
                    <ServiceProvider value={fields.serviceProvider} />
                )}
                {freelance && <FreelanceGeneral value={fields.freelance} />}
                {freelance && <FreelanceInfo value={fields.freelanceInfo} />}
                {fields.remarks && <Remarks value={fields.remarks} />}
                {fields.attachments.length > 0 && (
                    <Attachments value={fields.attachments} />
                )}

                <Button
                    onClick={this.props.onBack}
                    variant="contained"
                    color="primary"
                    style={{
                        marginTop: "3em",
                    }}
                >
                    Terug
                </Button>
            </div>
        );
    }
}

export default Summary;
