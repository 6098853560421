// @flow

import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import moment from "moment";

import style from "./style.module.scss";

export type Value = {
    bedrijfsvoorheffing: string,
    korteOmschrijving: string,
    startBv: number,
    eindBv: number,
};

type Props = {
    value: Value,
};

const LABEL_VARIANT = "body2";
const VALUE_VARIANT = "body1";

const Tax = ({ value }: Props) => {
    return (
        <React.Fragment>
            <div className={style.sectiontitle}>
                <Typography variant="h5">Bedrijfsvoorheffing</Typography>
                <Divider />
            </div>
            <Grid container>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        Opdracht komt in aanmerking voor vermindering
                        Bedrijfsvoorheffing
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.bedrijfsvoorheffing}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        Korte omschrijving
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.korteOmschrijving || "-"}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>Startdatum</Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.startBv > 0
                            ? moment.unix(value.startBv).format("DD/MM/YYYY")
                            : "n.v.t"}
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={LABEL_VARIANT}>
                        Geschatte einddatum
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Typography variant={VALUE_VARIANT}>
                        {value.eindBv > 0
                            ? moment.unix(value.eindBv).format("DD/MM/YYYY")
                            : "n.v.t"}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Tax;
